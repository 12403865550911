import { useQuery } from "react-query";
import { ShiftLogsApprovalsListResponse } from "../../../types";
import { ShiftLogsApi } from "../../../apis";
import { shiftLogApprovalsListQueryKey } from "../query-cache";

export const useGetShiftLogApprovals = (locationId: string, shiftId: string, date: string) => {
  const { data, error, isLoading, refetch, isSuccess, isFetching } = useQuery<
    ShiftLogsApprovalsListResponse,
    Error
  >(
    shiftLogApprovalsListQueryKey(locationId, shiftId, date),
    () => ShiftLogsApi.LogsEntry.getApprovals(locationId, shiftId, date),
    {
      enabled: Boolean(locationId && shiftId && date)
    }
  );

  return {
    approvals: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    isFetching,
    getApprovals: refetch
  };
};
