import { useMutation, useQueryClient } from "react-query";
import { ShiftLogs, ShiftLogsListResponse } from "../../../types";
import { ShiftLogsApi } from "../../../apis";
import { shiftLogEntryListQueryKey } from "../query-cache";

export const useCreateLogEntry = (locationId: string, shiftId: string, date: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(
    (logEntries: ShiftLogs[]) => ShiftLogsApi.LogsEntry.create(logEntries),
    {
      onSuccess: (shiftLog: ShiftLogsListResponse) => {
        queryClient.setQueryData<ShiftLogsListResponse | undefined>(
          shiftLogEntryListQueryKey(locationId, shiftId, date),
          (currentState: ShiftLogsListResponse | undefined) => {
            if (!currentState) return undefined;
            return {
              ...currentState,
              data: [...currentState.data, ...shiftLog.data]
            };
          }
        );
      }
    }
  );

  return {
    log: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    createLogEntry: mutateAsync
  };
};
