import { useMutation, useQueryClient } from "react-query";
import { shiftLogsTemplatesListQueryKey } from "./state";
import { ShiftLogsApi } from "../../..";
import { AssignedLocationsDocumentResponse, ShiftLogsTemplateListResponse } from "../../../types";

type Variables = {
  shiftLogTemplateId: string;
  locationId: string;
  isDefault: boolean;
};

export const useMakeDefaultTemplate = (siteId: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(
    ({ shiftLogTemplateId, locationId, isDefault }: Variables) => {
      return ShiftLogsApi.Templates.makeDefaultTemplateToLocation(
        shiftLogTemplateId,
        locationId,
        isDefault
      );
    },
    {
      onSuccess: (data: AssignedLocationsDocumentResponse, payload: Variables) => {
        queryClient.setQueryData<ShiftLogsTemplateListResponse | undefined>(
          shiftLogsTemplatesListQueryKey(siteId),
          (currentState: ShiftLogsTemplateListResponse | undefined) => {
            if (!currentState) return undefined;
            const newTemplatesList = currentState.data.map((shiftLogTemplate) => {
              return {
                ...shiftLogTemplate,
                assignedLocations: shiftLogTemplate.assignedLocations.map((location) => {
                  return {
                    ...location,
                    isDefault:
                      shiftLogTemplate.shiftLogTemplateId === payload.shiftLogTemplateId &&
                      location.locationId === payload.locationId &&
                      payload.isDefault
                  };
                })
              };
            });
            return {
              ...currentState,
              data: newTemplatesList
            };
          }
        );
      }
    }
  );

  return {
    makeDefaultTemplate: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
