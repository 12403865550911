import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { lazy } from "react";
import { SpinningLoader } from "@packages/theme-mui-v5";
import ApplicationAccessManagement from "@packages/application-access-management";
import applicationConfig, { basePath } from "./config";

const UpdateLocationConfiguration = SpinningLoader(
  lazy(() => import("./drawers/location-configuration/UpdateLocationConfiguration"))
);
const ViewTemplates = SpinningLoader(lazy(() => import("./pages/Templates/ViewTemplates")));
const EditTemplateDetails = SpinningLoader(
  lazy(() => import("./drawers/templates/EditTemplateDetails"))
);
const DeleteTemplate = SpinningLoader(lazy(() => import("./drawers/templates/DeleteTemplate")));
const CreateTemplate = SpinningLoader(lazy(() => import("./drawers/templates/CreateTemplate")));
const EditTemplate = SpinningLoader(lazy(() => import("./drawers/templates/EditTemplate")));

const ShiftLogs = SpinningLoader(lazy(() => import("./pages/ShiftLogs/ShiftLogs")));

const Router = () => {
  return (
    <BrowserRouter basename={basePath}>
      <Routes>
        <Route path="logs" element={<ShiftLogs />} />
        <Route path="configuration" element={<UpdateLocationConfiguration />} />

        <Route path="templates/:templateId/edit" element={<EditTemplateDetails />} />
        <Route path="templates" element={<ViewTemplates />}>
          <Route path="create" element={<CreateTemplate />} />
          <Route path=":templateId/delete" element={<DeleteTemplate />} />
          <Route path=":templateId/edit-details" element={<EditTemplate />} />
        </Route>

        <Route
          path="admin/*"
          element={
            <ApplicationAccessManagement
              basePath={basePath}
              applicationId={applicationConfig.applicationId}
            />
          }
        />
        <Route path="*" element={<Navigate to="logs" replace />} />
      </Routes>
      <Outlet />
    </BrowserRouter>
  );
};

export default Router;
