import { useMutation } from "react-query";
import { ShiftLogsApi } from "../../../apis";
import { ShiftLogs } from "../../../types";

interface Variables {
  previousLogEntry: Partial<ShiftLogs>;
  logEntry: Partial<ShiftLogs>;
}

export const useUpdateLogEntry = () => {
  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(
    ({ previousLogEntry, logEntry }: Variables) =>
      ShiftLogsApi.LogsEntry.update(previousLogEntry, logEntry)
  );

  return {
    log: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    updateLogEntry: mutateAsync
  };
};
