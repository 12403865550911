import { useQuery } from "react-query";

import { ShiftLogsApi } from "../../..";
import { ShiftLogAttachmentListResponse } from "../../../types";
import { shiftLogAttachmentListQueryKey } from "../query-cache";

export const useGetShiftLogAttachments = ({
  shiftLogId,
  enabled
}: {
  shiftLogId: string;
  enabled?: boolean;
}) => {
  const queryKey = shiftLogAttachmentListQueryKey(shiftLogId);
  const queryFn = () => ShiftLogsApi.LogAttachments.getShiftLogAttachments({ shiftLogId });

  const { isLoading, isSuccess, isError, data, error, refetch } =
    useQuery<ShiftLogAttachmentListResponse>({
      queryKey,
      queryFn,
      enabled
    });

  return {
    loading: isLoading,
    shiftLogAttachments: data?.data,
    isSuccess,
    isError,
    error,
    refetch
  };
};
