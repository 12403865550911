import { QueryKeyFunction } from "../../types";

export const shiftLogEntryListQueryKey = (locationId: string, shiftId: string, date: string) => {
  return ["shift-logs", "logs", "list", locationId, shiftId, date];
};

export const shiftLogApprovalsListQueryKey = (
  locationId: string,
  shiftId: string,
  date: string
) => {
  return ["shift-logs", "approvals", "list", locationId, shiftId, date];
};

export const shiftLogEntryByIdQueryKey = (shiftLogEntryId: string) => {
  return ["shift-logs", "log", "single", shiftLogEntryId];
};

export const shiftLogAttachmentListQueryKey: QueryKeyFunction = (shiftLogId: string) => [
  "shift-logs",
  "attachments",
  "list",
  { shiftLogId }
];
