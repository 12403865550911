import { useMutation, useQueryClient } from "react-query";
import { ShiftLogsApi, LocationConfiguration } from "../../../../";
import { locationConfigurationQueryKey } from "./query-cache";

interface Variables {
  previousLocationConfiguration: Partial<LocationConfiguration>;
  locationConfiguration: Partial<LocationConfiguration>;
}

export const useUpdateLocationConfiguration = (locationId: string) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(
    ({ previousLocationConfiguration, locationConfiguration }: Variables) =>
      ShiftLogsApi.LocationConfiguration.update(
        previousLocationConfiguration,
        locationConfiguration
      ),
    {
      onSuccess: (locationConfiguration) => {
        queryClient.setQueryData(
          locationConfigurationQueryKey(locationId),
          () => locationConfiguration
        );
      }
    }
  );

  return {
    locationConfiguration: data,
    updateLocationConfiguration: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
