import { useQuery } from "react-query";
import { ShiftLogsTemplateListResponse } from "../../../types";
import { ShiftLogsApi } from "../../..";
import { shiftLogsTemplatesListQueryKey } from "./state";

export const useGetTemplatesBySitId = (siteId: string) => {
  const { data, error, isLoading, isFetching, refetch } = useQuery<
    ShiftLogsTemplateListResponse,
    Error
  >(
    shiftLogsTemplatesListQueryKey(siteId),
    () => ShiftLogsApi.Templates.getTemplatesBySiteId(siteId, { size: 1000 }),
    {
      enabled: Boolean(siteId)
    }
  );

  return {
    templates: data?.data,
    error: error,
    loading: isLoading,
    isFetching: isFetching,
    refetchtemplates: refetch
  };
};
