import { useMutation, useQueryClient } from "react-query";
import { ShiftLogsTemplateListResponse } from "../../../types";
import { shiftLogsTemplatesListQueryKey } from "./state";
import { ShiftLogsApi } from "../../..";

export const useDeleteTemplate = (shiftLogTemplateId: string, siteId: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(
    () => {
      return ShiftLogsApi.Templates.delete(shiftLogTemplateId);
    },
    {
      onSuccess: () => {
        queryClient.setQueryData<ShiftLogsTemplateListResponse | undefined>(
          shiftLogsTemplatesListQueryKey(siteId),
          (currentState: ShiftLogsTemplateListResponse | undefined) => {
            if (!currentState) return undefined;

            // Delete
            const newEntities = currentState.data.filter((state) => {
              return state.shiftLogTemplateId !== shiftLogTemplateId;
            });

            return {
              ...currentState,
              data: [...newEntities]
            };
          }
        );
      }
    }
  );

  return {
    deleteTemplate: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
