import { useMutation, useQueryClient } from "react-query";
import { shiftLogsTemplatesListQueryKey } from "./state";
import { ShiftLogsApi } from "../../..";
import { AssignedLocationsDocumentResponse, ShiftLogsTemplateListResponse } from "../../../types";

type Variables = {
  shiftLogTemplateId: string;
  locationId: string;
};

export const useUnassignTemplateFromLocation = (siteId: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(
    ({ shiftLogTemplateId, locationId }: Variables) => {
      return ShiftLogsApi.Templates.unassignTemplateFromLocation(shiftLogTemplateId, locationId);
    },
    {
      onSuccess: (data: AssignedLocationsDocumentResponse, payload: Variables) => {
        queryClient.setQueryData<ShiftLogsTemplateListResponse | undefined>(
          shiftLogsTemplatesListQueryKey(siteId),
          (currentState: ShiftLogsTemplateListResponse | undefined) => {
            if (!currentState) return undefined;
            const newTemplatesList = currentState.data.map((shiftLogTemplate) => {
              if (shiftLogTemplate.shiftLogTemplateId === payload.shiftLogTemplateId) {
                return {
                  ...shiftLogTemplate,
                  assignedLocations: shiftLogTemplate.assignedLocations.filter(
                    (location) => location.locationId !== payload.locationId
                  )
                };
              }
              return shiftLogTemplate;
            });
            return {
              ...currentState,
              data: newTemplatesList
            };
          }
        );
      }
    }
  );

  return {
    unassignTemplate: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
