import { useQuery } from "react-query";
import { ShiftLogsListResponse } from "../../../types";
import { ShiftLogsApi } from "../../../apis";
import { shiftLogEntryListQueryKey } from "../query-cache";

export const useGetLogEntries = (locationId: string, shiftId: string, date: string) => {
  const { data, error, isLoading, refetch, isSuccess, isFetching } = useQuery<
    ShiftLogsListResponse,
    Error
  >(
    shiftLogEntryListQueryKey(locationId, shiftId, date),
    () => ShiftLogsApi.LogsEntry.get(locationId, shiftId, date),
    {
      enabled: Boolean(locationId && shiftId && date)
    }
  );

  return {
    logEntries: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    isFetching,
    getLogEntries: refetch
  };
};
