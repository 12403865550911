import { useQuery } from "react-query";
import { HTTPError, LocationConfigurationDocumentResponse, ShiftLogsApi } from "../../../../";
import { locationConfigurationQueryKey } from "./query-cache";

/**
 * Fetch the location configuration for a specific location. If it does not exists
 * create a new location configuration with default values and return that
 * @param locationId - Id of location to fetch
 * @returns {Promise<LocationConfigurationDocumentResponse}
 */
const getLocationConfiguration = async (locationId: string) => {
  try {
    const data = await ShiftLogsApi.LocationConfiguration.get(locationId);

    return data;
  } catch (error) {
    if (error instanceof HTTPError && error.response.status === 404) {
      const baseConfig = {
        locationId,
        isEnabled: true,
        isApprovalRequired: true,
        isUnapprovedLogDisplayed: false,
        isLogLockedAfterApproval: true,
        isImportLogsOwnerNameIncluded: true,
        autoSaveFrequency: 0,
        approvals: []
      };

      return ShiftLogsApi.LocationConfiguration.create(baseConfig);
    }
  }
};

export const useGetLocationConfiguration = (locationId: string) => {
  const { data, error, isLoading, isFetching, refetch } = useQuery<
    LocationConfigurationDocumentResponse,
    Error
  >(locationConfigurationQueryKey(locationId), () => getLocationConfiguration(locationId), {
    enabled: Boolean(locationId)
  });

  return {
    locationConfiguration: data?.data,
    error: error,
    loading: isLoading,
    isFetching: isFetching,
    refetchProducts: refetch
  };
};
