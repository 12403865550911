import { useMutation, useQueryClient } from "react-query";
import { ShiftLogsTemplate, ShiftLogsTemplateListResponse } from "../../../types";
import { shiftLogsTemplatesListQueryKey } from "./state";
import { ShiftLogsApi } from "../../..";

export const useCreateTemplate = (siteId: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(
    (template: ShiftLogsTemplate) => ShiftLogsApi.Templates.create(template),
    {
      onSuccess: (shiftLogTemplate) => {
        queryClient.setQueryData<ShiftLogsTemplateListResponse | undefined>(
          shiftLogsTemplatesListQueryKey(siteId),
          (currentState: ShiftLogsTemplateListResponse | undefined) => {
            if (!currentState) return undefined;
            return {
              ...currentState,
              data: [...currentState.data, shiftLogTemplate.data]
            };
          }
        );
      }
    }
  );

  return {
    shiftRotation: data,
    error: error,
    loading: isLoading,
    isSuccess,
    createTemplate: mutateAsync
  };
};
