import { useQuery } from "react-query";
import { ShiftLogsTemplateDocumentResponse } from "../../../types";
import { ShiftLogsApi } from "../../..";
import { shiftLogsTemplatesByIdQueryKey } from "./state";

export const useGetTemplate = (shiftLogTemplateId: string) => {
  const { data, error, isLoading, isFetching, refetch } = useQuery<
    ShiftLogsTemplateDocumentResponse,
    Error
  >(
    shiftLogsTemplatesByIdQueryKey(shiftLogTemplateId),
    () => ShiftLogsApi.Templates.getOne(shiftLogTemplateId),
    {
      enabled: Boolean(shiftLogTemplateId)
    }
  );

  return {
    template: data?.data,
    error: error,
    loading: isLoading,
    isFetching: isFetching,
    refetchtemplates: refetch
  };
};
