import { useMutation, useQueryClient } from "react-query";

import { createShiftLogAttachment } from "../../../apis/shift-logs/log-attachments";
import { CreateAttachmentRequest, ShiftLogAttachmentListResponse } from "../../../types";
import { shiftLogAttachmentListQueryKey } from "../query-cache";

export const useCreateShiftLogAttachment = ({ shiftLogId }: { shiftLogId: string }) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: (request: CreateAttachmentRequest) => {
      return createShiftLogAttachment({ shiftLogId, request });
    },
    onSuccess: () => {
      const queryKey = shiftLogAttachmentListQueryKey(shiftLogId);

      // Invalidate shift log attachments query data.
      queryClient.invalidateQueries<ShiftLogAttachmentListResponse>(queryKey, {
        exact: true
      });
    }
  });

  return {
    createShiftLogAttachment: mutateAsync,
    attach: data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
