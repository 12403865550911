import { useMutation, useQueryClient } from "react-query";

import { ShiftLogsApi } from "../../../apis";
import { shiftLogAttachmentListQueryKey } from "../query-cache";
import { ShiftLogAttachmentListResponse } from "../../../types/shift-logs";

export type Variables = {
  shiftLogId: string;
  shiftLogAttachmentId: string;
};

export const useDeleteShiftLogAttachment = ({ shiftLogId }: { shiftLogId: string }) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isError, isSuccess, error } = useMutation({
    mutationFn: ({ shiftLogAttachmentId }: Variables) => {
      return ShiftLogsApi.LogAttachments.deleteShiftLogAttachment({
        shiftLogId,
        shiftLogAttachmentId
      });
    },
    onSuccess: (_, variables: Variables) => {
      // Updated shift logs attachments query data.
      const queryKey = shiftLogAttachmentListQueryKey(shiftLogId);

      queryClient.setQueryData<ShiftLogAttachmentListResponse | undefined>(
        queryKey,
        (currentState: ShiftLogAttachmentListResponse | undefined) => {
          if (!currentState) return undefined;

          const nextAttachments = currentState.data.filter((state) => {
            return state.shiftLogAttachmentId !== variables.shiftLogAttachmentId;
          });

          return {
            ...currentState,
            data: [...nextAttachments]
          };
        }
      );
    }
  });

  return {
    loading: isLoading,
    isError,
    isSuccess,
    error,
    deleteShiftLogAttachment: mutateAsync
  };
};
