import { useMutation } from "react-query";
import { ShiftLogsApi } from "../../../apis";

type Variables = {
  shiftLogId: string;
  logText: string;
};

export const useUpdateLogEntryText = () => {
  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(
    ({ shiftLogId, logText }: Variables) =>
      ShiftLogsApi.LogsEntry.updateShiftLogEntryText(shiftLogId, logText)
  );

  return {
    log: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    updateLogEntryText: mutateAsync
  };
};
