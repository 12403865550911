import { useMutation, useQueryClient } from "react-query";
import { ShiftLogsTemplate, ShiftLogsTemplateListResponse } from "../../../types";
import { shiftLogsTemplatesListQueryKey, shiftLogsTemplatesByIdQueryKey } from "./state";
import { ShiftLogsApi } from "../../..";

interface Variables {
  previousTemplate: ShiftLogsTemplate;
  template: Partial<ShiftLogsTemplate>;
}

export const useUpdateTemplate = (siteId: string) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(
    ({ previousTemplate, template }: Variables) =>
      ShiftLogsApi.Templates.update(template, previousTemplate),
    {
      onSuccess: (shiftLogTemplate) => {
        queryClient.setQueryData<ShiftLogsTemplateListResponse | undefined>(
          shiftLogsTemplatesListQueryKey(siteId),
          (currentState: ShiftLogsTemplateListResponse | undefined) => {
            if (!currentState) return undefined;
            return {
              ...currentState,
              data: currentState.data.map((state) =>
                state.shiftLogTemplateId === shiftLogTemplate.data.shiftLogTemplateId
                  ? shiftLogTemplate.data
                  : state
              )
            };
          }
        );
        queryClient.invalidateQueries(
          shiftLogsTemplatesByIdQueryKey(shiftLogTemplate.data.shiftLogTemplateId)
        );
      }
    }
  );

  return {
    shiftRotation: data,
    error: error,
    loading: isLoading,
    isSuccess,
    updateTemplate: mutateAsync
  };
};
