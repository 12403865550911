import { useMutation, useQueryClient } from "react-query";
import {
  ShiftLogsApproval,
  ShiftLogsApprovalsListResponse,
  ShiftLogsApprovalsDocumentResponse
} from "../../../types";
import { ShiftLogsApi } from "../../../apis";
import { shiftLogApprovalsListQueryKey } from "../query-cache";

export const useApproveShiftLog = (locationId: string, shiftId: string, date: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(
    (approve: ShiftLogsApproval) => ShiftLogsApi.LogsEntry.approve(approve),
    {
      onSuccess: (data: ShiftLogsApprovalsDocumentResponse) => {
        const queryKey = shiftLogApprovalsListQueryKey(locationId, shiftId, date);
        queryClient.setQueryData<ShiftLogsApprovalsListResponse | undefined>(
          queryKey,
          (currentState: ShiftLogsApprovalsListResponse | undefined) => {
            if (!currentState) return undefined;
            return {
              ...currentState,
              data: [...currentState.data, data.data]
            };
          }
        );
      }
    }
  );

  return {
    log: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    approve: mutateAsync
  };
};
